import {
  Box,
  Button,
  TextField,
  Fab,
  Checkbox,
  FormControlLabel,
} from "@mui/material";

import {
  selectedPageSelector,
  setSelectedPage,
} from "app/rkt_query/SelectedStuffReducer";
import {
  LANGUAGES,
  selectedLanguageSelector,
} from "app/rkt_query/SettingsReducer";
import {
  BLUE_PRINTS,
  useAddPageMutation,
  useGetPageQuery,
  useUpdatePageMutation,
  useGetAllRegionsQuery,
  useDeleteImageMutation,
} from "app/rkt_query/storeApis";
import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import DZDropdown, {
  DZSimpleDropDown,
} from "../../../@jumbo/dz-components/dropdowns/DZDropdown";
import DZTextField from "../../../@jumbo/dz-components/textboxes/DZTextField";

import { useJumboDialog } from "../../../@jumbo/components/JumboDialog/hooks/useJumboDialog";
import Snackbar, {
  NoHideSnackbar,
} from "@jumbo/dz-components/snackbar/Snackbar";
import DZTextValidation, {
  MultiDZTextValidation,
} from "@jumbo/dz-components/textboxes/DZTextValidation";
import SimpleBackdrop from "@jumbo/dz-components/backdrop/BackDrop";

import Grid from "@mui/material/Grid";
import { DZImageCircle } from "@jumbo/dz-components/imageCircle/DZImage";
import { Config } from "constant";
import DZLabel, {
  DzNoBackgroundLabel,
} from "@jumbo/dz-components/labels/DZLabel";

import { Avatar, Card, CardContent, Rating, Typography } from "@mui/material";

import Div from "@jumbo/shared/Div";
import { dialogButtonsStyle } from "@jumbo/dz-components/styles/styles";
import useResponseError from "@jumbo/dz-components/custom-hooks/useResponseError";

import DzTextEditorMultilingual from "@jumbo/dz-components/TextEditor/DzTextEditorMultilingual";
import { useSnackBarManager } from "app/hooks/useSnackBarManager";
import ImageUpload from "app/DZcomponents/ImageUpload/ImageUpload";
import SingleImageUpload from "app/DZcomponents/SingleImageUpload/SingleImageUpload";

const textContent =
  "<h2>Awesome Rich Content</h2>\n" +
  "<p>Suspendisse id sollicitudin dui. <strong>Vestibulum eu sapien pharetra,</strong> bibendum ligula id, ullamcorper ligula.</p>\n" +
  "\n" +
  "<ul>\n" +
  "        <li>ullamcorper ligula</li>\n" +
  "        <li>Duis vel neque</li>\n" +
  "</ul>\n" +
  "\n" +
  "<p><em>Sed feugiat hendrerit risus, quis efficitur massa facilisis vitae. Aliquam erat volutpat. </em></p>\n";
const AddUpdatePage = ({ onClose, learning_journey_id, selectedId }) => {
  // const chatbot_types = ['scale', 'note', 'buddy'];
  const { showDialog, hideDialog } = useJumboDialog();

  const { data: use_get_all_regions } = useGetAllRegionsQuery();
  const { fnShowSnackBar } = useSnackBarManager();

  const dispatch = useDispatch();

  const selectedPage = useSelector(selectedPageSelector);

  const {
    data: res_get,
    isLoading: isLoadingPage,
    error: errorPage,
  } = useGetPageQuery(selectedPage?.id);

  // console.log("ghfffhgh", res_get)
  const [updatePage, responseUpdate] = useUpdatePageMutation();
  const [addPage, responseAdd] = useAddPageMutation();

  const [deleteImage, responseDeleteImage] = useDeleteImageMutation();

  const bluePrint = BLUE_PRINTS.page;
  // const bluePrintEngagement = BLUE_PRINTS.engagement;
  // console.log(bluePrintEngagement);

  const [pageState, setPageState] = useState(null);
  const [pageImage, setPageImage] = useState(null);

  const selectedLanguage = useSelector(selectedLanguageSelector);
  const [showBackDrop, setShowBackDrop] = useState(false);
  const [editorState, setEditorState] = useState(selectedLanguage);

  //this code is use to control infinity loops and backend required error. custom hook
  const { error, resetCount } = useResponseError(responseAdd, responseUpdate);

  const regionIds = res_get?.data?.regions?.map((region) =>
    parseInt(region?.region_id, 10)
  );

  useEffect(() => {
    setPageState({
      ...pageState,
      image: pageImage,
    });
  }, [pageImage]);

  useEffect(() => {
    setPageState(
      selectedPage
        ? { ...res_get?.data?.page, regions_id: regionIds ?? [] }
        : bluePrint
    );
  }, [res_get]);

  const addUpdatePageData = async (action) => {
    const formData = new FormData();

    formData.append("id", res_get?.data?.page?.id);
    formData.append("title", JSON.stringify(pageState.title));
    formData.append("heading_title", JSON.stringify(pageState.heading_title));
    formData.append(
      "heading_description",
      JSON.stringify(pageState.heading_description)
    );

    formData.append("image", pageState.image);
    formData.append("url", pageState.url);
    formData.append("meta_title", JSON.stringify(pageState.meta_title));
    formData.append(
      "meta_description",
      JSON.stringify(pageState.meta_description)
    );
    formData.append("is_active", pageState.is_active == true ? 1 : 0);
    formData.append("regions_id", JSON.stringify(pageState.regions_id));
    // return false;

    const res =
      action === "add" ? await addPage(formData) : await updatePage(formData);
    if (res?.data?.message) {
      fnShowSnackBar(res?.data?.message || "Done successfully");
    } else {
      fnShowSnackBar(res?.error?.data?.message, "error");
    }
  };

  const regions = use_get_all_regions?.data?.regions?.map((region) => region);

  return (
    <div>
      {showBackDrop && (
        <SimpleBackdrop
          responseAdd={responseAdd}
          responseUpdate={responseUpdate}
        />
      )}
      <Snackbar
        responseAdd={
          responseAdd?.isSuccess === true ? responseAdd : responseUpdate
        }
      />

      {pageState ? (
        <div
          style={{
            marginTop: 25,
            display: "flex",
            flexDirection: "column",
            gap: 25,
            marginBottom: 60,
          }}
        >
          <SingleImageUpload
            setImageFile={setPageImage}
            imageLink={`${Config?.digitalOceanLinkBlogMediumImg}${res_get?.data?.page?.image}`}
            stateImage={res_get?.data?.page?.image}
            onDelete={() => deleteImage({ page_id: res_get?.data?.page?.id })}
          />
          {/* <SingleImageUpload
            // onClose,
            // uploadImageFile,
            setImageFile={setPageImage}
            // imageName,
            imageLink={
              Config?.digitalOceanLinkBlogMediumImg + res_get?.data?.page?.image
            }
            stateImage = {res_get?.data?.page?.image}
            // stateImage={null}
            onDelete={deleteImage(res_get?.data?.page?.id)}
          /> */}

          <Grid container spacing={3.75}>
            <Grid item xs={4} md={4} lg={4}>
              <Div sx={{ mb: 0.5, mt: 1 }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={pageState?.is_active == 0 ? false : true}
                      onChange={(e) => {
                        setPageState((x) => ({
                          ...x,
                          is_active: e.target.checked,
                        }));
                      }}
                    />
                  }
                  label="Is Active"
                />
              </Div>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <Div sx={{ mb: 0.5, mt: 1 }}>
                <MultiDZTextValidation
                  error={error?.[0]}
                  label="Title"
                  variant="outlined"
                  value={pageState?.title?.[selectedLanguage] || ""}
                  onChange={(e) => {
                    console.log("Input Value:", selectedLanguage);
                    setPageState((x) => ({
                      ...x,
                      title: { ...x.title, [selectedLanguage]: e.target.value },
                    }));
                  }}
                />
              </Div>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <Div sx={{ mb: 0.5, mt: 1 }}>
                <DZTextValidation
                  error={error?.url}
                  label="Page Url"
                  variant="outlined"
                  value={pageState?.url}
                  onChange={(e) => {
                    setPageState((x) => ({
                      ...x,
                      url: e.target.value,
                    }));
                  }}
                />
              </Div>
            </Grid>
            {/* regions */}
            <Grid item xs={12} md={12} lg={12}>
              <Div sx={{ mb: 0.5, mt: 1 }}>
                <DzNoBackgroundLabel title={"Regions"} />
              </Div>
            </Grid>
            {regions?.map((region) => (
              <Grid item xs={5} md={2} lg={2} key={region?.id}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={pageState?.regions_id?.includes(region?.id)}
                      name={region?.language}
                      onChange={(e) => {
                        const checked = e.target.checked;
                        setPageState((prevState) => ({
                          ...prevState,
                          regions_id: checked
                            ? [...prevState?.regions_id, region?.id]
                            : prevState?.regions_id.filter(
                                (id) => id !== region?.id
                              ),
                        }));
                      }}
                    />
                  }
                  label={region?.language}
                />
              </Grid>
            ))}

            <Grid item xs={12} md={12} lg={12}>
              <Div sx={{ mb: 0.5, mt: 1 }}>
                <DZTextValidation
                  error={error?.meta_title}
                  label="Meta Title"
                  variant="outlined"
                  value={pageState?.meta_title?.[selectedLanguage] || ""}
                  onChange={(e) => {
                    console.log("Input Value:", selectedLanguage);
                    setPageState((x) => ({
                      ...x,
                      meta_title: {
                        ...x.meta_title,
                        [selectedLanguage]: e.target.value,
                      },
                    }));
                  }}
                />
              </Div>
            </Grid>
          </Grid>
          <DZTextField
            label="Meta Description"
            variant="outlined"
            noOfRows={3}
            value={pageState?.meta_description?.[selectedLanguage] || ""}
            onChange={(e) => {
              console.log("Input Value:", selectedLanguage);
              setPageState((x) => ({
                ...x,
                meta_description: {
                  ...x.meta_description,
                  [selectedLanguage]: e.target.value,
                },
              }));
            }}
          />
          <Grid item xs={12} md={12} lg={12}>
            <Div sx={{ mb: 0.5, mt: 1 }}>
              <MultiDZTextValidation
                error={error?.heading_title}
                label="Page Heading"
                variant="outlined"
                value={pageState?.heading_title?.[selectedLanguage] || ""}
                onChange={(e) => {
                  setPageState((x) => ({
                    ...x,
                    heading_title: {
                      ...x.heading_title,
                      [selectedLanguage]: e.target.value,
                    },
                  }));
                }}
              />
            </Div>
          </Grid>
          <DzTextEditorMultilingual
            data={pageState?.heading_description?.[selectedLanguage] || ""}
            onChange={(newContent) => {
              setPageState((x) => ({
                ...x,
                heading_description: {
                  ...x.heading_description,
                  [selectedLanguage]: newContent,
                },
              }));
            }}
          />

          <div style={dialogButtonsStyle}>
            <Button
              variant="outlined"
              onClick={() => {
                hideDialog();
                dispatch(setSelectedPage(null));
              }}
            >
              Cancel
            </Button>
            {res_get?.data?.page?.id ? (
              <Button
                variant="contained"
                onClick={() => {
                  addUpdatePageData();
                  setShowBackDrop(true);
                  resetCount();
                  dispatch(setSelectedPage(null));
                }}
              >
                Update
              </Button>
            ) : (
              <Button
                variant="contained"
                onClick={() => {
                  addUpdatePageData("add");
                  setShowBackDrop(true);
                  resetCount();
                  dispatch(setSelectedPage(null));
                }}
              >
                Add
              </Button>
            )}
          </div>
          {/* dispatch(setSelectedPage(null)); */}
        </div>
      ) : (
        <div>Loading....</div>
      )}
    </div>
  );
};

export default AddUpdatePage;
