import JumboDemoCard from "@jumbo/components/JumboDemoCard";
import React, { useState } from "react";
import {
  BLUE_PRINTS,
  useDeletePageMutation,
  useDeleteUrlRedirectMutation,
  useGetAllUrlRedirectsQuery,
} from "app/rkt_query/storeApis";
import { useDispatch, useSelector } from "react-redux";
import { selectedLanguageSelector } from "app/rkt_query/SettingsReducer";
import DataTable from "../../../@jumbo/dz-components/data-table/DataTable";
import { setSelectedUrlRedirect } from "app/rkt_query/SelectedStuffReducer";
import { useJumboDialog } from "@jumbo/components/JumboDialog/hooks/useJumboDialog";
import Snackbar from "@jumbo/dz-components/snackbar/Snackbar";
import SimpleBackdrop from "@jumbo/dz-components/backdrop/BackDrop";
import { useNavigate } from "react-router-dom";
import AddUpdateUrlRedirect from "./AddUpdateUrlRedirect";

const URLRedirect = () => {
  const dispatch = useDispatch();

  const { showDialog, hideDialog } = useJumboDialog();

  const [deleteUrlRedirect, responseDelete] = useDeleteUrlRedirectMutation();

  const { data: res_get_all, isLoading: isLoadingUrlRedirects } =
    useGetAllUrlRedirectsQuery();
  console.log("res_get_all1=======>", res_get_all);

  const [showMode, setShowMode] = useState(false);
  const selectedLanguage = useSelector(selectedLanguageSelector);

  const bluePrint = BLUE_PRINTS.urlRedirect;
  bluePrint.addRecord = () => {
    setShowMode(false);
    dispatch(setSelectedUrlRedirect(null));
  };
  bluePrint.showRecord = (row) => {
    setShowMode(true);
    dispatch(setSelectedUrlRedirect(row));
  };
  bluePrint.editRecord = (row) => {
    setShowMode(false);
    dispatch(setSelectedUrlRedirect(row));
  };
  bluePrint.deleteRecord = (row) => {
    dispatch(setSelectedUrlRedirect(row));
  };
  bluePrint.handleDelete = (id) => {
    deleteUrlRedirect(id);
  };

  const navigate = useNavigate();

  const handleBack = () => {
    navigate("/categories");
  };

  return (
    <>
      <span>{showMode}</span>
      {responseDelete.isLoading === true && (
        <SimpleBackdrop responseAdd={responseDelete} />
      )}
      {responseDelete?.isSuccess === true && (
        <Snackbar responseAdd={responseDelete} />
      )}

      <JumboDemoCard
        title="URL Redirects"
        wrapperSx={{
          p: 0,
          backgroundColor: "background.paper",
          "&:last-child": { pb: 0 },
        }}
        sx={{ mt: 5 }}
      >
        <div style={{ width: "100%" }}>
          <DataTable
            btnTitleAdd="URL Redirect"
            data={res_get_all?.data?.urls}
            bluePrint={bluePrint}
            // ViewModel={() => <ViewUrlRedirect />}
            ModalContent={() => (
              <AddUpdateUrlRedirect onClose={hideDialog} fullscreen={true} />
            )}
            showAddButton={true}
          />
        </div>
      </JumboDemoCard>
    </>
  );
};
export default URLRedirect;
