import React, { useState, useRef } from "react";
import "./SingleImageUpload.css";
function SingleImageUpload({
  setImageFile,
  imageName = "Image",
  imageLink,
  onDelete,
  stateImage = null,
}) {
  const [selectedImage, setSelectedImage] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [loading, setLoading] = useState(false); // Loader state
  const fileInputRef = useRef(null);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      handleFile(file);
    }
  };

  const handleFile = (file) => {
    setSelectedImage(file);
    const imageURL = URL.createObjectURL(file);
    setPreviewImage(imageURL);
    setImageFile(file);
  };

  const removeImage = () => {
    setSelectedImage(null);
    setPreviewImage(null);
    setImageFile(null);
  };

  const handleDelete = async () => {
    if (!onDelete || !stateImage) return;

    setLoading(true); // Start loading
    try {
      await onDelete();
      removeImage(); // Clear the image
    } catch (error) {
      console.error("Error deleting image:", error);
    } finally {
      setLoading(false); // End loading
    }
  };

  return (
    <div
      className="relative flex justify-center items-center w-full h-full bg-opacity-50"
      onDrop={(e) => e.preventDefault()}
      onDragOver={(e) => e.preventDefault()}
      onDragEnter={(e) => e.preventDefault()}
    >
      <div className="bg-white rounded-lg shadow-xl w-80 p-6">
        <div className="flex flex-col items-center gap-2">
          <span className="text-lg font-semibold">Upload {imageName}</span>
        </div>
        <div className="w-full">
          <input
            type="file"
            accept="image/*"
            onChange={handleImageChange}
            ref={fileInputRef}
            className="hidden"
          />
          <div
            className={`relative flex justify-center items-center w-full min-h-48 cursor-pointer ${
              loading ? "pointer-events-none" : ""
            }`}
            onClick={() => fileInputRef.current.click()}
          >
            {previewImage || stateImage ? (
              <>
                <img
                  src={previewImage ?? imageLink}
                  alt="Preview"
                  className="w-48 h-48 object-cover rounded-lg bg-gray-200"
                />
                {loading && (
                  <div className="absolute inset-0 bg-black bg-opacity-50 flex justify-center items-center">
                    <div className="loader"></div>
                  </div>
                )}
              </>
            ) : (
              <div className="text-center text-sm text-blue-600">
                <i className="ri-upload-cloud-2-fill text-4xl"></i>
                <span>Add Your {imageName}</span>
              </div>
            )}
          </div>
        </div>
        <div className="flex justify-end w-full mt-4">
          {(previewImage || stateImage) && (
            <button
              className={`bg-blue-600 text-white px-4 py-2 rounded-md text-sm font-semibold ${
                loading ? "opacity-50 cursor-not-allowed" : ""
              }`}
              onClick={() => (previewImage ? removeImage() : handleDelete())}
              disabled={loading} // Disable button while loading
            >
              {loading ? "Deleting..." : previewImage ? "Cancel" : "Delete"}
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default SingleImageUpload;
