import {
  Box,
  Button,
  TextField,
  Fab,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText,
} from "@mui/material";
import {
  selectedUrlRedirectSelector,
  setSelectedUrlRedirect,
} from "app/rkt_query/SelectedStuffReducer";
import {
  BLUE_PRINTS,
  useAddUrlRedirectMutation,
  useGetUrlRedirectQuery,
  useUpdateUrlRedirectMutation,
} from "app/rkt_query/storeApis";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import DZTextField from "../../../@jumbo/dz-components/textboxes/DZTextField";
import { useJumboDialog } from "../../../@jumbo/components/JumboDialog/hooks/useJumboDialog";
import Snackbar from "@jumbo/dz-components/snackbar/Snackbar";
import DZTextValidation from "@jumbo/dz-components/textboxes/DZTextValidation";
import SimpleBackdrop from "@jumbo/dz-components/backdrop/BackDrop";
import Grid from "@mui/material/Grid";
import Div from "@jumbo/shared/Div";
import { dialogButtonsStyle } from "@jumbo/dz-components/styles/styles";
import useResponseError from "@jumbo/dz-components/custom-hooks/useResponseError";
import { useSnackBarManager } from "app/hooks/useSnackBarManager";

const AddUpdateUrlRedirect = ({ onClose }) => {
  const { showDialog, hideDialog } = useJumboDialog();
  const { fnShowSnackBar } = useSnackBarManager();
  const dispatch = useDispatch();

  const selectedUrlRedirect = useSelector(selectedUrlRedirectSelector);

  const {
    data: res_get,
    isLoading,
    error: errorUrlRedirect,
  } = useGetUrlRedirectQuery(selectedUrlRedirect?.id);

  const [updateUrlRedirect, responseUpdate] = useUpdateUrlRedirectMutation();
  const [addUrlRedirect, responseAdd] = useAddUrlRedirectMutation();

  const bluePrint = BLUE_PRINTS.urlRedirect;

  const [urlRedirectState, setUrlRedirectState] = useState(null);
  const [showBackDrop, setShowBackDrop] = useState(false);

  const { error, resetCount } = useResponseError(responseAdd, responseUpdate);

  useEffect(() => {
    setUrlRedirectState(
      selectedUrlRedirect ? { ...res_get?.data?.url } : bluePrint
    );
  }, [res_get]);

  const addUpdateUrlRedirectData = async (action) => {
    const formData = new FormData();

    formData.append("id", res_get?.data?.url?.id);
    formData.append("old_url", urlRedirectState.old_url);
    formData.append("table_id", urlRedirectState.table_id);
    formData.append("domain", urlRedirectState.domain);
    formData.append("table", urlRedirectState.table);

    const res =
      action === "add"
        ? await addUrlRedirect(formData)
        : await updateUrlRedirect(formData);

    if (res?.data?.message) {
      fnShowSnackBar(res?.data?.message || "Done successfully");
      hideDialog();
    } else {
      fnShowSnackBar(res?.error?.data?.message, "error");
    }
  };

  console.log("res_get?.data?.url?.id", res_get?.data?.url?.id);

  return (
    <div>
      {showBackDrop && (
        <SimpleBackdrop
          responseAdd={responseAdd}
          responseUpdate={responseUpdate}
        />
      )}

      <Snackbar
        responseAdd={
          responseAdd?.isSuccess === true ? responseAdd : responseUpdate
        }
      />

      {urlRedirectState ? (
        <div
          style={{
            marginTop: 25,
            display: "flex",
            flexDirection: "column",
            gap: 25,
            marginBottom: 60,
          }}
        >
          <Grid container spacing={3.75}>
            <Grid item xs={12} md={12} lg={12}>
              <Div sx={{ mb: 0.5, mt: 1 }}>
                <DZTextValidation
                  error={error?.old_url}
                  label="Old URL"
                  variant="outlined"
                  fullWidth
                  value={urlRedirectState?.old_url}
                  onChange={(e) => {
                    setUrlRedirectState((x) => ({
                      ...x,
                      old_url: e.target.value,
                    }));
                  }}
                />
              </Div>
            </Grid>

            {/* <Grid item xs={12} md={12} lg={12}>
              <Div sx={{ mb: 0.5, mt: 1 }}>
                <Select
                  error={Boolean(error?.table)}
                  label="Table"
                  variant="outlined"
                  fullWidth
                  value={urlRedirectState?.table}
                  onChange={(e) => {
                    setUrlRedirectState((x) => ({
                      ...x,
                      table: e.target.value,
                    }));
                  }}
                >
                  <MenuItem value="hotels">Hotels</MenuItem>
                  <MenuItem value="pages">Pages</MenuItem>
                </Select>
              </Div>
            </Grid> */}
            <Grid item xs={12} md={12} lg={12}>
              <Div sx={{ mb: 0.5, mt: 1 }}>
                <FormControl
                  fullWidth
                  variant="outlined"
                  error={Boolean(error?.table)}
                >
                  <InputLabel id="table-select-label">Table</InputLabel>
                  <Select
                    labelId="table-select-label"
                    value={urlRedirectState?.table || ""}
                    onChange={(e) => {
                      setUrlRedirectState((prevState) => ({
                        ...prevState,
                        table: e.target.value,
                      }));
                    }}
                    label="Table"
                  >
                    <MenuItem value="hotels">Hotels</MenuItem>
                    <MenuItem value="pages">Pages</MenuItem>
                  </Select>
                  {error?.table && (
                    <FormHelperText>{error?.table}</FormHelperText>
                  )}
                </FormControl>
              </Div>
            </Grid>

            <Grid item xs={12} md={12} lg={12}>
              <Div sx={{ mb: 0.5, mt: 1 }}>
                <DZTextValidation
                  error={error?.domain}
                  label="Domain"
                  variant="outlined"
                  fullWidth
                  value={urlRedirectState?.domain}
                  onChange={(e) => {
                    setUrlRedirectState((x) => ({
                      ...x,
                      domain: e.target.value,
                    }));
                  }}
                />
              </Div>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <Div sx={{ mb: 0.5, mt: 1 }}>
                <TextField
                  error={Boolean(error?.table_id)}
                  label="Table ID"
                  variant="outlined"
                  type="number"
                  fullWidth
                  value={urlRedirectState?.table_id || ""} // Default to an empty string if no value
                  onChange={(e) => {
                    setUrlRedirectState((prevState) => ({
                      ...prevState,
                      table_id: parseInt(e.target.value) || "", // Parse the number or reset if empty
                    }));
                  }}
                  helperText={error?.table_id && error?.table_id}
                />
              </Div>
            </Grid>
          </Grid>

          <div style={dialogButtonsStyle}>
            <Button
              variant="outlined"
              onClick={() => {
                hideDialog();
                dispatch(setSelectedUrlRedirect(null));
              }}
            >
              Cancel
            </Button>

            {res_get?.data?.url?.id ? (
              <Button
                variant="contained"
                onClick={() => {
                  addUpdateUrlRedirectData();
                  setShowBackDrop(true);
                  resetCount();
                  dispatch(setSelectedUrlRedirect(null));
                }}
              >
                Update
              </Button>
            ) : (
              <Button
                variant="contained"
                onClick={() => {
                  addUpdateUrlRedirectData("add");
                  setShowBackDrop(true);
                  resetCount();
                  dispatch(setSelectedUrlRedirect(null));
                }}
              >
                Add
              </Button>
            )}
          </div>
        </div>
      ) : (
        <div>Loading....</div>
      )}
    </div>
  );
};

export default AddUpdateUrlRedirect;
