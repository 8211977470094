import { createSlice } from '@reduxjs/toolkit';

export const slice = createSlice({
    name: 'SelectedStuffReducer',
    initialState: {
        selectedGallery: null,
        selectedHotel: null,
        selectedSport: null,
        selectedLeadServiceType: null,
        selectedHotelSport: null,
        selectedAirport: null,
        selectedRegion: null,
        selectedCountry: null,
        selectedLeadOfferFeedback: null,
        selectedCity: null,
        selectedPopularHotel: null,
        selectedLeadService: null,
        selectedPage: null, //
        selectedUrlRedirect: null, // Url Redirect
        selectedReference: null,
        selectedAdmin: null,
        selectedLeadUser: null,
        selectedLead: null,
        selectedLeadOffer: null,
        selectedLeadContract: null,
        selectedLeadContractCondition: null,
        selectedContractConditionType: null,
        selectedLeadId: null,
        selectedLeadUniqueId: null,
        selectedChatId: null,
        selectedFaqs: null,
   
        selectedUserLogin: null,
      
        selectedUser: null,
       
      
    },

    reducers: {
        setSelectedUserLogin: (state, action) => {
            state.selectedUserLogin = action.payload;
        },
        setSelectedHotel: (state, action) => {
            state.selectedHotel = action.payload;
        },
        setSelectedSport: (state, action) => {
            state.selectedSport = action.payload;
        },
        setSelectedLeadServiceType: (state, action) => {
            state.selectedLeadServiceType = action.payload;
        },
        setSelectedGallery: (state, action) => {
            state.selectedGallery = action.payload;
        },
        setSelectedHotelSport: (state, action) => {
            state.selectedHotelSport = action.payload;
        },
        setSelectedAirport: (state, action) => {
            state.selectedAirport = action.payload;
        },
        setSelectedRegion: (state, action) => {
            state.selectedRegion = action.payload;
        },
        setSelectedCountry: (state, action) => {
            state.selectedCountry = action.payload;
        },
        setSelectedLeadOfferFeedback: (state, action) => {
            state.selectedLeadOfferFeedback = action.payload;
        },
        setSelectedCity: (state, action) => {
            state.selectedCity = action.payload;
        },
        setSelectedPopularHotel: (state, action) => {
            state.selectedPopularHotel = action.payload;
        },
        setSelectedLeadService: (state, action) => {
            state.selectedLeadService = action.payload;
        }, 
        setSelectedPage: (state, action) => { //
            state.selectedPage = action.payload;
        },
        setSelectedUrlRedirect: (state, action) => { // Url Redirect
            state.selectedUrlRedirect = action.payload;
        },

        setSelectedFaqs: (state, action) => {
            state.selectedFaqs = action.payload;
        },

        setSelectedReference: (state, action) => {
            state.selectedReference = action.payload;
        },
        setSelectedLead: (state, action) => {
            state.selectedLead = action.payload;
        },
        setSelectedLeadOffer: (state, action) => {
            state.selectedLeadOffer = action.payload;
        },
        setSelectedLeadContract: (state, action) => {
            state.selectedLeadContract = action.payload;
        },
        setSelectedLeadContractCondition: (state, action) => {
            state.selectedLeadContractCondition = action.payload;
        },
        setSelectedContractConditionType: (state, action) => {
            state.selectedContractConditionType = action.payload;
        },
        setSelectedLeadUser: (state, action) => {
            state.selectedLeadUser = action.payload;
        },
        setSelectedAdmin: (state, action) => {
            state.selectedAdmin = action.payload;
        },
        setSelectedLeadId: (state, action) => {
            state.selectedLeadId = action.payload;
        },
        setSelectedLeadUniqueId: (state, action) => {
            state.selectedLeadUniqueId = action.payload;
        },
        setSelectedChatId: (state, action) => {
            state.selectedChatId = action.payload;
        },




      

        setSelectedUser: (state, action) => {
            console.log("redux data", action)
            state.selectedUser = action.payload;
        },

     
        //old


        // setSelectedUser: (state, action) => {
        //     state.selectedUser = action.payload;
        // },
        resetSelectedStuffReducer: (state) => {
            state.selectedUserLogin = null;
            state.selectedSport = null;
            state.selectedHotelSport = null;
            state.selectedHotel = null;
            state.selectedAirport = null;
            state.selectedGallery = null;
            state.selectedRegion= null;
            state.selectedCountry= null;
            state.selectedLeadOfferFeedback= null;
            state.selectedCity= null;
            state.selectedPopularHotel= null;
            state.selectedLeadService= null;
            state.selectedLeadServiceType= null;
            state.selectedPage= null; //
            state.selectedUrlRedirect= null; // Url Redirect
            state.selectedReference= null;
            state.selectedAdmin= null;
            state.selectedLeadUser= null;
            state.selectedLead= null;
            state.selectedLeadOffer= null;
            state.selectedLeadContract= null;
            state.selectedLeadContractCondition= null;
            state.selectedContractConditionType= null;
          
            state.selectedFaqs = null;

      
            state.selectedUser = null;
     


        },
    },
});

export const {
    setSelectedUserLogin,
    setSelectedSport,
    setSelectedHotelSport,
    setSelectedHotel,
    setSelectedAirport,
    setSelectedRegion,
    setSelectedCountry,
    setSelectedLeadOfferFeedback,
    setSelectedCity,
    setSelectedPopularHotel,
    setSelectedLeadService,
    setSelectedLeadServiceType,
    setSelectedPage, //
    setSelectedUrlRedirect, // Url Redirect
    setSelectedReference,
    setSelectedAdmin,
    setSelectedLeadUser,
    setSelectedLead,
    setSelectedLeadOffer,
    setSelectedLeadContract,
    setSelectedLeadContractCondition,
    setSelectedContractConditionType,
    setSelectedGallery,
    setSelectedLeadId,
    setSelectedLeadUniqueId,
    setSelectedChatId,

    setSelectedFaqs,


 

    setSelectedUser,

 

} = slice.actions;
// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched
export const selectedGallerySelector = (state) => state.SelectedStuffReducer.selectedGallery;
export const selectedUserLoginSelector = (state) => state.SelectedStuffReducer.selectedUserLogin;
export const selectedHotelSelector = (state) => state.SelectedStuffReducer.selectedHotel;
export const selectedSportSelector = (state) => state.SelectedStuffReducer.selectedSport;
export const selectedLeadServiceTypeSelector = (state) => state.SelectedStuffReducer.selectedLeadServiceType;
export const selectedHotelSportSelector = (state) => state.SelectedStuffReducer.selectedHotelSport;
export const selectedAirportSelector = (state) => state.SelectedStuffReducer.selectedAirport;
export const selectedRegionSelector = (state) => state.SelectedStuffReducer.selectedRegion;
export const selectedCountrySelector = (state) => state.SelectedStuffReducer.selectedCountry;
export const selectedLeadOfferFeedbackSelector = (state) => state.SelectedStuffReducer.selectedLeadOfferFeedback;
export const selectedCitySelector = (state) => state.SelectedStuffReducer.selectedCity;
export const selectedPopularHotelSelector = (state) => state.SelectedStuffReducer.selectedPopularHotel;
export const selectedLeadServiceSelector = (state) => state.SelectedStuffReducer.selectedLeadService;
export const selectedPageSelector = (state) => state.SelectedStuffReducer.selectedPage; //
export const selectedUrlRedirectSelector = (state) => state.SelectedStuffReducer.selectedUrlRedirect; // Url Redirect
export const selectedReferenceSelector = (state) => state.SelectedStuffReducer.selectedReference;
export const selectedLeadSelector = (state) => state.SelectedStuffReducer.selectedLead;
export const selectedLeadOfferSelector = (state) => state.SelectedStuffReducer.selectedLeadOffer;
export const selectedLeadContractSelector = (state) => state.SelectedStuffReducer.selectedLeadContract;
export const selectedLeadContractConditionSelector = (state) => state.SelectedStuffReducer.selectedLeadContractCondition;
export const selectedContractConditionTypeSelector = (state) => state.SelectedStuffReducer.selectedContractConditionType;
export const selectedLeadUserSelector = (state) => state.SelectedStuffReducer.selectedLeadUser;
export const selectedAdminSelector = (state) => state.SelectedStuffReducer.selectedAdmin;
export const selectedLeadIdSelector = (state) => state.SelectedStuffReducer.selectedLeadId;
export const selectedLeadUniqueIdSelector = (state) => state.SelectedStuffReducer.selectedLeadUniqueId;
export const selectedChatIdSelector = (state) => state.SelectedStuffReducer.selectedChatId;

export const selectedFaqsSelector = (state) => state.SelectedStuffReducer.selectedFaqs;


export const selectedUserSelector = (state) => state.SelectedStuffReducer.selectedUser;

//old



export default slice.reducer;