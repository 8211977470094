import JumboDemoCard from '@jumbo/components/JumboDemoCard';
import React, { useEffect, useState } from 'react'

import { useGetAllHotelsQuery, BLUE_PRINTS, useGetHotelQuery, useDeleteHotelMutation,  } from 'app/rkt_query/storeApis';
import { useDispatch, useSelector } from 'react-redux';
import { selectedLanguageSelector } from 'app/rkt_query/SettingsReducer';
import DataTable from '../../../@jumbo/dz-components/data-table/DataTable';
import { selectedUserLoginSelector, setSelectedHotel } from 'app/rkt_query/SelectedStuffReducer';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import Snackbar from '@jumbo/dz-components/snackbar/Snackbar';
import SimpleBackdrop from '@jumbo/dz-components/backdrop/BackDrop';
import { useLocation, useNavigate } from 'react-router-dom';
// import ViewHotel from './ViewHotel';
import AddUpdateHotel from './AddUpdateHotel.js';
import HeaderButtons from '@jumbo/dz-components/header-buttons/HeaderButtons';
import DzPreviews from '../extensions/dropzone/components/DzPreviews';
import { useJumboApp } from '@jumbo/hooks';
import { LAYOUT_NAMES } from 'app/layouts/layouts';


const HotelList = () => {



  const { setActiveLayout } = useJumboApp();
  const selectedUserLogin = useSelector(selectedUserLoginSelector);
     React.useEffect(() => {
         setActiveLayout(LAYOUT_NAMES.VERTICAL_DEFAULT);
         if(selectedUserLogin){
             window.location.reload(false);
         }
     }, [selectedUserLogin]);


  const dispatch = useDispatch();
  const location = useLocation();
  const { showDialog, hideDialog } = useJumboDialog();
  const [deleteHotel, responseDelete] = useDeleteHotelMutation();



  const { data: res_get_all,  } = useGetAllHotelsQuery();
  const [showMode, setShowMode] = useState(false);
  const selectedLanguage = useSelector(selectedLanguageSelector);


  const bluePrint = BLUE_PRINTS.hotel
  bluePrint.addRecord = () => { setShowMode(false); dispatch(setSelectedHotel(null)); }
  bluePrint.showRecord = (row) => { setShowMode(true); dispatch(setSelectedHotel(row)); }
  bluePrint.editRecord = (row) => {  setShowMode(false); dispatch(setSelectedHotel(row)); }
  bluePrint.deleteRecord = (row) => { dispatch(setSelectedHotel(row)); }
  bluePrint.handleDelete = (id) => { deleteHotel(id); }


  const navigate = useNavigate();

  


  return (
    <>
      {/* <StoreTokenCheck /> */}
      <span>{showMode}</span>
      {responseDelete.isLoading === true && <SimpleBackdrop responseAdd={responseDelete} />}
      {responseDelete?.isSuccess === true && <Snackbar responseAdd={responseDelete} />}

     

      <JumboDemoCard
        title="Hotels"
        wrapperSx={{
          p: 0,
          backgroundColor: "background.paper",
          "&:last-child": { pb: 0 },
        }}
        sx={{ mt: 5 }}
      >
           {/* <div style={{ marginTop: '0px', display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
            <HeaderButtons onClick={() => { bluePrint.addRecord(); }} />
          </div> */}

        <div style={{ width: "100%" }}>
          <DataTable
            btnTitleAdd="Hotel"
            data={res_get_all?.data?.hotels}
            bluePrint={bluePrint}
            showHeaderAddButton={true}
            goToPage={"/hotels/new-hotel"}
            ImageDzDrop={({hotelName})=><DzPreviews onClose={hideDialog} hotelName={hotelName}  />}
            viewTitle="Add Images"
            showHideViewTitle={false}
            showAddButton={false}
            addAndViewTitle="Sports"
            hideViewBtn={false}
            // ViewModel={() => <DzPreviews />}
            // ModalContent={() => <AddUpdateHotel onClose={hideDialog} fullscreen={true} />}
            // showAddButton={true}
          />
         
        </div>
      </JumboDemoCard>
    </>
  );
}


export default HotelList