import React, { useState } from "react";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import ApartmentOutlinedIcon from "@mui/icons-material/ApartmentOutlined";
import LibraryBooksOutlinedIcon from "@mui/icons-material/LibraryBooksOutlined";
import LocalAirportIcon from "@mui/icons-material/LocalAirport";
import PriceChangeIcon from "@mui/icons-material/PriceChange";
import ImportContactsOutlinedIcon from "@mui/icons-material/ImportContactsOutlined";
import LocalLibraryOutlinedIcon from "@mui/icons-material/LocalLibraryOutlined";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import SupervisorAccountOutlinedIcon from "@mui/icons-material/SupervisorAccountOutlined";
import ProductionQuantityLimitsIcon from "@mui/icons-material/ProductionQuantityLimits";
import CategoryIcon from "@mui/icons-material/Category";
import AppsIcon from "@mui/icons-material/Apps";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import FormatListNumberedRtlOutlinedIcon from "@mui/icons-material/FormatListNumberedRtlOutlined";
import DashboardIcon from "@mui/icons-material/Dashboard";
import { ROLE } from "app/rkt_query/SettingsReducer";
import useCheckRoles from "@jumbo/dz-components/custom-hooks/useCheckRoles";
import { decryptStoredData } from "app/pages/storetoken/StoreTokenCheck";
import { Config } from "constant";
import { selectedUserLoginSelector } from "app/rkt_query/SelectedStuffReducer";
import { useSelector } from "react-redux";
import RoomServiceIcon from "@mui/icons-material/RoomService";
import KayakingIcon from "@mui/icons-material/Kayaking";
import SouthAmericaIcon from "@mui/icons-material/SouthAmerica";
import PublicIcon from "@mui/icons-material/Public";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import CollectionsIcon from "@mui/icons-material/Collections";
import PagesIcon from "@mui/icons-material/Pages";

import LinkIcon from "@mui/icons-material/Link";

import MiscellaneousServicesIcon from "@mui/icons-material/MiscellaneousServices";
import HeatPumpIcon from "@mui/icons-material/HeatPump";
import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import ChecklistIcon from "@mui/icons-material/Checklist";
import HvacIcon from "@mui/icons-material/Hvac";
import RateReviewIcon from "@mui/icons-material/RateReview";
import StarIcon from "@mui/icons-material/Star";
// useCheckRoles()
//  const GetFromRedux = () => {
//     const [allowances, setAllowances] = useState([]);
//  const roleName= useSelector(selectedUserLoginSelector)
//  setAllowances(roleName)
//  return  allowances;

//     }
export const checkRole = (status) => {
  switch (status) {
    case ROLE.SuperAdmin:
      return ROLE.SuperAdmin;
    case ROLE.Sales:
      return ROLE.Sales;

    default:
      return ROLE.Sales;
  }
};

const role = decryptStoredData(Config.roleName);

export const superAdminCondition = () => {
  //   const role_name=GetFromRedux();
  return checkRole(role) === ROLE.SuperAdmin;
};

export const adminAndSalesCondition = () => {
  return checkRole(role) === ROLE.SuperAdmin || checkRole(role) === ROLE.Sales;
};

const menus = [
  // {

  //     label: 'home',
  //     type: "section",
  //     children: [
  //         {
  //             uri: "/",
  //             label: 'Dashboard',
  //             type: "nav-item",
  //             visible: true,
  //             icon: <DashboardIcon sx={{ fontSize: 20 }} />,
  //         },
  //         // {

  //         //     uri: "/order-list",
  //         //     label: 'Orders List',
  //         //     type: "nav-item",
  //         //     visible: true,
  //         //     icon: <FormatListNumberedRtlOutlinedIcon sx={{ fontSize: 20 }} />

  //         // },
  //         // {
  //         //     uri: "/orders",
  //         //     label: 'Orders',
  //         //     type: "nav-item",
  //         //     icon: <ListAltOutlinedIcon sx={{ fontSize: 20 }} />
  //         // },
  //     ]

  // },

  superAdminCondition() && {
    label: "modules",
    type: "section",
    children: [
      // {
      //     uri: "/courses",
      //     label: 'Course',
      //     type: "nav-item",
      //     icon: <LocalLibraryOutlinedIcon sx={{ fontSize: 20 }} />
      // },
      {
        uri: "/hotels",
        label: "Hotel",
        type: "nav-item",
        visible: true,
        icon: <RoomServiceIcon sx={{ fontSize: 20 }} />,
      },
      {
        uri: "/popular-hotels",
        label: "Popular Hotel",
        type: "nav-item",
        visible: true,
        icon: <StarIcon sx={{ fontSize: 20 }} />,
      },
      {
        uri: "/sports",
        label: "Sport",
        type: "nav-item",
        visible: true,
        icon: <KayakingIcon sx={{ fontSize: 20 }} />,
      },
      {
        uri: "/regions",
        label: "Region",
        type: "nav-item",
        visible: true,
        icon: <SouthAmericaIcon sx={{ fontSize: 20 }} />,
      },
      {
        uri: "/airports",
        label: "Airport",
        type: "nav-item",
        visible: true,
        icon: <LocalAirportIcon sx={{ fontSize: 20 }} />,
      },
      {
        uri: "/prices",
        label: "Prices",
        type: "nav-item",
        visible: true,
        icon: <PriceChangeIcon sx={{ fontSize: 20 }} />,
      },
      {
        uri: "/countries",
        label: "Country",
        type: "nav-item",
        visible: true,

        icon: <PublicIcon sx={{ fontSize: 20 }} />,
      },
      {
        uri: "/cities",
        label: "City",
        type: "nav-item",
        visible: true,

        icon: <LocationCityIcon sx={{ fontSize: 20 }} />,
      },
      {
        uri: "/pages",
        label: "Page",
        type: "nav-item",
        visible: true,

        icon: <PagesIcon sx={{ fontSize: 20 }} />,
      },
      {
        uri: "/url-redirect",
        label: "Url Redirect",
        type: "nav-item",
        visible: true,

        icon: <LinkIcon sx={{ fontSize: 20 }} />,
      },
      {
        uri: "/references",
        label: "Reference",
        type: "nav-item",
        visible: true,

        icon: <LocalLibraryOutlinedIcon sx={{ fontSize: 20 }} />,
      },

      {
        uri: "/gallery",
        label: "Gallery",
        type: "nav-item",
        visible: true,

        icon: <CollectionsIcon sx={{ fontSize: 20 }} />,
      },

      {
        uri: "/feed-back",
        label: "Feedback",
        type: "nav-item",
        visible: true,

        icon: <RateReviewIcon sx={{ fontSize: 20 }} />,
      },

      {
        uri: "/faqs",
        label: "FAQs",
        type: "nav-item",
        visible: true,

        icon: <QuestionAnswerIcon sx={{ fontSize: 20 }} />,
      },
      // {
      //     uri: "/sizes",
      //     label: 'Size',
      //     type: "nav-item",
      //     visible: true,

      //     icon: <LocalLibraryOutlinedIcon sx={{ fontSize: 20 }} />
      // },
      // {
      //     uri: "/orders",
      //     label: 'Orders',
      //     type: "nav-item",
      //     visible: true,

      //     icon: <ListAltOutlinedIcon sx={{ fontSize: 20 }} />
      // },
    ],
  },

  adminAndSalesCondition() && {
    label: "modules",
    type: "section",
    children: [
      {
        uri: "/leads",
        label: "Lead",
        type: "nav-item",
        visible: true,

        icon: <LeaderboardIcon sx={{ fontSize: 20 }} />,
      },
      {
        uri: "/chats",
        label: "Chat",
        type: "nav-item",
        visible: true,

        icon: <ChatOutlinedIcon sx={{ fontSize: 20 }} />,
      },
    ],
  },
  superAdminCondition() && {
    label: "service",
    type: "section",
    children: [
      {
        label: "Services",
        type: "collapsible",
        icon: <GroupOutlinedIcon sx={{ fontSize: 20 }} />,
        children: [
          {
            uri: "/lead-services-type",
            label: "Service Type",
            type: "nav-item",
            visible: true,

            icon: <ChecklistIcon sx={{ fontSize: 20 }} />,
          },
          {
            uri: "/lead-services",
            label: "Lead Services",
            type: "nav-item",
            visible: true,

            icon: <MiscellaneousServicesIcon sx={{ fontSize: 20 }} />,
          },
        ],
      },
    ],
  },
  superAdminCondition() && {
    label: "condition",
    type: "section",
    children: [
      {
        label: "Conditions",
        type: "collapsible",
        icon: <GroupOutlinedIcon sx={{ fontSize: 20 }} />,
        children: [
          {
            uri: "/lead-contract-conditions-type",
            label: "Condition Type",
            type: "nav-item",
            visible: true,

            icon: <HvacIcon sx={{ fontSize: 20 }} />,
          },

          {
            uri: "/lead-contract-conditions",
            label: "Lead Conditions",
            type: "nav-item",
            visible: true,

            icon: <HeatPumpIcon sx={{ fontSize: 20 }} />,
          },
        ],
      },
    ],
  },

  superAdminCondition() && {
    label: "authPages",
    type: "section",
    children: [
      // {
      //     uri: "/restaurants",
      //     label: 'Restaurant',
      //     type: "nav-item",
      //     visible: true,
      //     icon: <AppsIcon sx={{ fontSize: 20 }} />,
      //     is_super:true,

      // },
      // {
      //     uri: "/restaurant-info",
      //     label: 'Restaurant Info',
      //     type: "nav-item",
      //     visible: adminManagerCondition(),
      //     icon: <ImportContactsOutlinedIcon sx={{ fontSize: 20 }} />
      // },
      // {
      //     uri: "/restaurant-time",
      //     label: 'Restaurant Timing',
      //     type: "nav-item",
      //     visible: adminManagerCondition(),
      //     icon: <AccessTimeOutlinedIcon sx={{ fontSize: 20 }} />
      // },
      {
        uri: "/admins",
        label: "Admin",
        type: "nav-item",
        visible: superAdminCondition(),
        icon: <SupervisorAccountOutlinedIcon sx={{ fontSize: 20 }} />,
        is_super: true,
      },
      {
        uri: "/users",
        label: "User",
        type: "nav-item",
        visible: superAdminCondition(),
        icon: <GroupOutlinedIcon sx={{ fontSize: 20 }} />,
      },
    ],
  },
];

export default menus;
