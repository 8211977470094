import React from "react";

import Admin from "../pages/admin";
import User from "../pages/user";
import Login2 from "../pages/auth/login2/index"
import Dashboard from "app/pages/dashboard/Dashboard";
import Login from './../pages/auth/login/Login';
// import UserProfile from './../pages/users/user-profile/UserProfile';
import ForgotPassword from './../pages/auth/forgot-password/ForgotPassword';
import ForgotPasswordCode from './../pages/auth/forgot-password-code/ForgotPasswordCode';
import ChangePassword from './../pages/auth/forgot-password-code/ChangePassword';
import Profile from './../pages/profile/index';

import LeadList from "app/pages/lead";




import { adminAndSalesCondition,  superAdminCondition } from "app/layouts/shared/sidebars/Sidebar/menus";
import SportList from "app/pages/sport";
import AirportList from "app/pages/airport";
import CountryList from "app/pages/country";
import CityList from "app/pages/city";
import PageList from "app/pages/pages";
import ReferenceList from "app/pages/references";
import HotelList from "app/pages/hotel";
import AddUpdateHotel from "app/pages/hotel/AddUpdateHotel";
import HotelSportList from "app/pages/hotel_sport";
import LeadOfferList from "app/pages/lead_offer";
import RegionList from "app/pages/regions";
import PricesLists from "app/pages/prices";
import GalleryList from "app/pages/gallery";
import LeadServiceList from "app/pages/lead-service";
import LeadContractConditionList from "app/pages/lead-contract-condition";
import ChatApp from "app/pages/chat/ChatApp";
import FAQS from "app/pages/faqs";
import ServiceType from "app/pages/lead-service-type";
import ContractType from "app/pages/contract-condition-type";
import LeadOfferFeedbackList from "app/pages/lead_offer_feedback";
import PopularHotelList from "app/pages/popular_hotel";
import UrlRedirectList from "app/pages/url-redirect";

const routes = [

    { path: "/", element: <Login /> },
    { path: "forgot-password",element: <ForgotPassword />  },
    { path: "verify-forgot-password", element: <ForgotPasswordCode /> },
    { path: "change-password", element: <ChangePassword />},
    { path: "/login", element: <Login2 />},
    { path: "/profile",element: <Profile />},
    { path: "/dashboard",  element: <Dashboard />},

      superAdminCondition()&&{ path: "/sports",element: <SportList />},
      superAdminCondition()&&{ path: "/regions",element: <RegionList />},
      superAdminCondition()&&{ path: "/airports",element: <AirportList />},
      superAdminCondition()&&{ path: "/prices",element: <PricesLists />},
      superAdminCondition()&& { path: "/countries",element: <CountryList />},
      superAdminCondition()&&{ path: "/cities", element: <CityList />},
      superAdminCondition()&&{ path: "/pages", element: <PageList /> },
      superAdminCondition()&&{ path: "/url-redirect", element: <UrlRedirectList /> },
      superAdminCondition()&&{ path: "/references",element: <ReferenceList />},
      superAdminCondition()&&{ path: "/hotels",element: <HotelList />},
      superAdminCondition()&&{ path: "/popular-hotels",element: <PopularHotelList />},
      superAdminCondition()&&{ path: "/hotels/new-hotel",element: <AddUpdateHotel />},
      superAdminCondition()&&{ path: "/hotel/sports",element: <HotelSportList />},
      adminAndSalesCondition()&&{ path: "/lead/lead-offers",element: <LeadOfferList />},
      // adminManagerCondition()&&{ path: "/sizes", element: <SizeList /> },
      adminAndSalesCondition()&&{ path: "/leads", element: <LeadList /> },
      adminAndSalesCondition()&&{ path: "/chats", element: <ChatApp/> },
      superAdminCondition()&&{ path: "/gallery", element: <GalleryList/> },
      superAdminCondition()&&{ path: "/lead-services-type", element: <ServiceType/> },
      superAdminCondition()&&{ path: "/feed-back", element: <LeadOfferFeedbackList/> },
      superAdminCondition()&&{ path: "/lead-services", element: <LeadServiceList/> },
      superAdminCondition()&&{ path: "/lead-contract-conditions-type", element: <ContractType/> },
      superAdminCondition()&&{ path: "/lead-contract-conditions", element: <LeadContractConditionList/> },
      superAdminCondition()&&{ path: "/faqs", element: <FAQS/> },
      // adminManagerCondition()&&{ path: "/restaurants", element: <RestaurantList />},
      // adminManagerCondition()&&{ path: "/restaurants",element: <RestaurantList />},
      // adminManagerCondition()&&{ path: "/restaurant-info",element: <UpdateViewRestaurantInfo />},
      // adminManagerCondition()&&{ path: "/restaurant-time", element: <UpdateViewRestaurantTime /> },
      superAdminCondition()&&{path: "/admins", element: <Admin /> },
      superAdminCondition()&& { path: "/users",element: <User />},
];

export default routes;